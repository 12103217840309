import React, {
  ComponentClass,
  FunctionComponent,
  lazy,
  LazyExoticComponent,
} from 'react';
import { Route, Switch } from 'react-router-dom';
import paths from 'router/paths';
import { getFleetUserCookie } from 'utilities/cookie';
import ProtectedRoute from './ProtectedRoute';

// lazy import needs relative paths to work correctly
// keep it alphabetically sorted
const About = lazy(() => import('../pages/About'));
const ArrivalInstructions = lazy(() => import('../pages/ArrivalInstructions'));
const AddressCarSelection = lazy(() => import('../pages/AddressCarSelection'));
const AddressSelection = lazy(() => import('../pages/AddressSelection'));
const AppointmentPreference = lazy(
  () => import('../pages/AppointmentPreference')
);
const AuthError = lazy(() => import('../pages/Errors/AuthError'));
const BatteryWarranty = lazy(() => import('../pages/BatteryWarranty'));
const CarConfirm = lazy(() => import('../pages/CarConfirm'));
const CarDetails = lazy(() => import('../pages/CarDetails'));
const CarPlate = lazy(() => import('../pages/CarPlate'));
const CarPlateNotFound = lazy(() => import('../pages/CarPlateNotFound'));
const FleetAppointments = lazy(() => import('../pages/FleetAppointments'));
const FleetVehicles = lazy(() => import('../pages/FleetVehicles'));
const FleetVehicleDetails = lazy(() => import('../pages/FleetVehicleDetails'));
const FleetOutstanding = lazy(() => import('../pages/FleetOutstanding'));
const FleetDashboard = lazy(() => import('../pages/FleetDashboard'));
const FleetInvoices = lazy(() => import('../pages/FleetInvoices'));
const FleetHealthReport = lazy(() => import('../pages/FleetHealthReports'));
const FleetVisitView = lazy(() => import('../pages/FleetVisitView'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const ForgotPasswordConfirmation = lazy(
  () => import('../pages/ForgotPasswordConfirmation')
);
const Garage = lazy(() => import('../pages/Garage/Garage'));
const GetQuote = lazy(() => import('../pages/GetQuote'));
const HealthReport = lazy(() => import('../pages/HealthReport'));
const HealthReportsList = lazy(() => import('../pages/HealthReportsList'));
const Help = lazy(() => import('../pages/Help'));
const Home = lazy(() => import('../pages/Home'));
const HowCanWeHelp = lazy(() => import('../pages/HowCanWeHelp'));
const Location = lazy(() => import('../pages/Location'));
const MobileValidation = lazy(() => import('../pages/MobileValidation'));
const MobileValidationUpdate = lazy(
  () => import('../pages/MobileValidationUpdate/MobileValidationUpdate')
);
const MyAccount = lazy(() => import('../pages/MyAccount'));
const MyCredits = lazy(() => import('../pages/MyCredits'));
const MyRepairs = lazy(() => import('../pages/MyRepairs'));
const MyCars = lazy(() => import('../pages/MyCars/MyCars'));
const MyRepair = lazy(() => import('../pages/MyRepair'));
const NotFound = lazy(() => import('../pages/NotFound'));
const Payment = lazy(() => import('../pages/Payment'));
const PossibleRepairs = lazy(() => import('../pages/PossibleRepairs'));
const Privacy = lazy(() => import('../pages/Privacy'));
const Register = lazy(() => import('../pages/Register'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const ResetPasswordConfirmation = lazy(
  () => import('../pages/ResetPasswordConfirmation')
);
const SignIn = lazy(() => import('../pages/SignIn'));
const Sitemap = lazy(() => import('../pages/Sitemap/Sitemap'));
const TimeSelection = lazy(() => import('../pages/TimeSelection'));
const Terms = lazy(() => import('../pages/Terms'));
const TermsAutopilot = lazy(() => import('../pages/TermsAutopilot'));
const VisitSelection = lazy(() => import('../pages/VisitSelection'));
const StateMandatedRequirements = lazy(
  () => import('../pages/StateMandatedRequirements')
);
const Warranty = lazy(() => import('../pages/Warranty'));
const Waitlist = lazy(() => import('../pages/Waitlist'));

const { HEALTH_REPORT_ENABLED } = window.RepairSmith;

const isFleetUser = getFleetUserCookie();

type RoutesProps = {
  path: string;
  component: FunctionComponent | ComponentClass | LazyExoticComponent<any>;
  exact?: boolean;
  protected?: boolean;
};

const routes: RoutesProps[] = [
  {
    path: paths.home,
    component: isFleetUser ? FleetDashboard : Home,
    exact: true,
  },
  {
    path: `${paths.home}+`,
    component: isFleetUser ? FleetDashboard : Home,
    exact: true,
  },
  {
    path: '/sign-in',
    component: SignIn,
  },
  {
    path: '/fleet/appointments/upcoming',
    component: FleetAppointments,
    protected: true,
    exact: true,
  },
  {
    path: '/fleet/appointments/completed',
    component: FleetAppointments,
    protected: true,
    exact: true,
  },
  {
    path: '/fleet/appointments/canceled',
    component: FleetAppointments,
    protected: true,
    exact: true,
  },
  {
    path: '/fleet/appointments/:visitRefNum',
    component: FleetVisitView,
    protected: true,
  },
  {
    path: '/fleet/appointments',
    component: FleetAppointments,
    protected: true,
    exact: true,
  },
  {
    path: '/fleet/outstanding/:apptType',
    component: FleetOutstanding,
    protected: true,
  },
  {
    path: '/fleet/outstanding/:apptType',
    component: FleetOutstanding,
    protected: true,
  },
  {
    path: paths.fleetOutstanding,
    component: FleetOutstanding,
    protected: true,
  },
  {
    path: paths.fleetDashboard,
    component: FleetDashboard,
    protected: true,
  },
  {
    path: paths.fleetHealthReports,
    component: FleetHealthReport,
    protected: true,
  },
  {
    path: '/fleet/vehicles/:carId',
    component: FleetVehicleDetails,
    protected: true,
    exact: true,
  },
  {
    path: paths.fleetVehicles,
    component: FleetVehicles,
    protected: true,
    exact: true,
  },
  {
    path: paths.fleetInvoices,
    component: FleetInvoices,
    protected: true,
    exact: true,
  },
  {
    path: '/forgot-password/confirmation',
    component: ForgotPasswordConfirmation,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/reset-password/confirmation',
    component: ResetPasswordConfirmation,
    protected: true,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    protected: true,
  },
  {
    path: paths.myCredits,
    component: MyCredits,
    protected: true,
  },
  {
    path: paths.myAccount,
    component: MyAccount,
    protected: true,
  },
  {
    path: '/garage',
    component: Garage,
    protected: true,
  },
  {
    path: paths.location,
    component: Location,
  },
  {
    path: paths.getQuote,
    component: GetQuote,
  },
  { path: paths.ymmt, component: CarDetails },
  {
    path: '/car-info/plate/not-found',
    component: CarPlateNotFound,
  },
  {
    path: paths.carPlate,
    component: CarPlate,
  },
  {
    path: paths.myCars,
    component: MyCars,
    protected: true,
  },
  {
    path: paths.addressCarSelection,
    component: AddressCarSelection,
    protected: true,
  },
  {
    path: paths.carConfirm,
    component: CarConfirm,
  },
  {
    path: paths.repairSelection,
    component: HowCanWeHelp,
  },

  {
    path: `${paths.carHealthReports}`,
    component: HEALTH_REPORT_ENABLED === 'true' ? HealthReportsList : NotFound,
    exact: true,
    protected: true,
  },
  {
    path: `${paths.carHealthReports}/:inspectionId`,
    component: HEALTH_REPORT_ENABLED === 'true' ? HealthReport : NotFound,
    exact: true,
  },

  {
    path: '/my-repairs',
    component: MyRepairs,
    exact: true,
    protected: true,
  },
  {
    path: '/my-repairs/:id',
    component: MyRepair,
    exact: true,
  },

  {
    path: paths.register,
    component: Register,
  },
  {
    path: paths.payment,
    component: Payment,
  },
  {
    path: paths.possibleRepairs,
    component: PossibleRepairs,
  },
  {
    path: paths.freeEstimate,
    component: PossibleRepairs,
  },
  {
    path: paths.addressSelection,
    component: AddressSelection,
  },
  {
    path: paths.appointmentPreference,
    component: AppointmentPreference,
  },
  {
    path: paths.arrivalInstructions,
    component: ArrivalInstructions,
  },
  { path: paths.timeSelection, component: TimeSelection },
  { path: paths.confirmation, component: MyRepair },
  { path: '/about/team', component: NotFound, exact: true },
  { path: '/about/team/:name', component: NotFound, exact: true },
  {
    path: '/about',
    component: About,
  },
  { path: '/help', component: Help, exact: true },
  { path: '/privacy', component: Privacy },
  { path: paths.terms, component: Terms, exact: true },
  { path: paths.termsAutopilot, component: TermsAutopilot },
  { path: paths.batteryWarranty, component: BatteryWarranty },
  { path: paths.mobileValidation, component: MobileValidation },
  { path: paths.mobileValidationUpdate, component: MobileValidationUpdate },
  { path: '/sitemap', component: Sitemap },
  { path: '/authorization/error', component: AuthError },
  { path: paths.visitSelection, component: VisitSelection },
  {
    path: '/state-mandated-requirements',
    component: StateMandatedRequirements,
  },
  { path: '/warranty', component: Warranty },
  {
    path: '/waitlist/:refNum/:appointmentId/:availabilityWindowOfferedId',
    component: Waitlist,
    protected: true,
  },
  { path: '*', component: NotFound },
];

const Routes = () => (
  <Switch>
    {routes.map((route) =>
      route.protected ? (
        <ProtectedRoute
          key={route.path}
          path={route.path}
          component={route.component}
          exact={route.exact}
        />
      ) : (
        <Route
          key={route.path}
          path={route.path}
          component={route.component}
          exact={route.exact}
        />
      )
    )}
  </Switch>
);

export default Routes;
