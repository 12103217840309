export const paths = {
  home: '/',
  location: '/location',
  getQuote: '/get-quote',
  addressCarSelection: '/address-car-selection',
  about: '/about',
  aboutNews: '/about/news',
  help: '/help',
  privacy: '/privacy',
  privacyAutopilot: '/privacy/autopilot',
  terms: '/terms',
  termsAutopilot: '/terms/autopilot',
  batteryWarranty: '/batterywarranty',
  sitemap: '/sitemap',
  ymmt: '/car-info/details',
  carConfirm: '/car-info/confirm',
  carPlate: '/car-info/plate',
  repairSelection: '/how-can-we-help',
  possibleRepairs: '/possible-repairs',
  timeSelection: '/time-selection',
  reschedule: '/reschedule',
  myCars: '/car-info/my-cars',
  confirmation: '/confirmation',
  register: '/register',
  addressSelection: '/address-selection',
  arrivalInstructions: '/arrival-instructions',
  appointmentPreference: '/appointment-preference',
  garage: '/garage',
  signIn: '/sign-in',
  resetPasswordConfirmation: '/reset-password/confirmation',
  carHealthReports: '/my-repairs/car-health-reports',
  mobileValidation: '/mobile-validation',
  mobileValidationNew: '/mobile-validation-new',
  mobileValidationUpdate: '/mobile-validation-update',
  myRepair: '/my-repairs',
  warranty: '/warranty',
  payment: '/add-payment',
  fleetAppointments: '/fleet/appointments',
  fleetOutstanding: '/fleet/outstanding',
  fleetOutstandingRecommended: '/fleet/outstanding/recommended',
  fleetHealthReports: '/fleet/car-health-reports',
  fleetVehicles: '/fleet/vehicles',
  fleetDashboard: '/fleet/dashboard',
  fleetInvoices: '/fleet/invoices',
  freeEstimate: '/free-estimate',
  waitlist: '/waitlist',
  myAccount: '/my-account',
  myCredits: '/my-account/my-credits',
  visitSelection: '/fleet/visit-selection',
};

export default paths;
