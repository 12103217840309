import cookie, { get } from 'js-cookie';
import { isNil, get as lodashGet } from 'lodash';
import {
  ACCESS_TOKEN,
  EMAIL_VERIFIED,
  REFRESH_TOKEN,
  RS_CONVERSION_INFO,
  FLEET_USER,
} from '../constants/cookies';

export const setCookie = (key: string, value: string) => {
  cookie.set(key, value, {
    expires: 1,
    path: '/',
  });
};

export const removeCookie = (key: string) => {
  cookie.remove(key, {
    expires: 1,
  });
  const separate = window.location.hostname.split('.');
  separate.shift();
  const currentdomain = separate.join('.');
  cookie.remove(key, {
    expires: 1,
    domain: `.${currentdomain}`,
  });
};

export const getCookie = (key: string): string | undefined => {
  return cookie.get(key);
};

export type UserTokens = {
  accessToken?: string;
  refreshToken?: string;
  emailVerified: boolean;
};

export const setAuthCookies = (action: { payload: UserTokens }) => {
  const { accessToken, refreshToken, emailVerified } = action.payload;

  if (!isNil(accessToken) && !isNil(refreshToken)) {
    setCookie(ACCESS_TOKEN, accessToken);
    setCookie(REFRESH_TOKEN, refreshToken);
    setCookie(EMAIL_VERIFIED, `${emailVerified}`);
  }
};

export const getAuthCookies = (): UserTokens => ({
  accessToken: get(ACCESS_TOKEN),
  refreshToken: get(REFRESH_TOKEN),
  emailVerified: Boolean(get(EMAIL_VERIFIED)),
});

export const resetAuthCookies = () => {
  removeCookie(ACCESS_TOKEN);
  removeCookie(REFRESH_TOKEN);
  removeCookie(EMAIL_VERIFIED);
  removeCookie(FLEET_USER);
};

export const getAnonymousConsumerId = (): string | undefined => {
  const conversionInfoCookie = getCookie(RS_CONVERSION_INFO) || '{}';
  return lodashGet(JSON.parse(conversionInfoCookie), 'trackingId');
};

export const setFleetUserCookie = (value: boolean) => {
  setCookie(FLEET_USER, String(value));
};

export const getFleetUserCookie = () => getCookie(FLEET_USER) === 'true';
