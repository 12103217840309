import React, { Suspense } from 'react';

import {
  StyledEngineProvider,
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import PageLoader from 'components/common/PageLoader';
import Snackbar from 'components/common/Snackbar';
import store from 'store/createStore';
import Document from 'Document';
import AnalyticsWrapper from 'AnalyticsWrapper';
import Routes from 'router/routes';
import theme from 'components/common/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const materialTheme = materialExtendTheme(theme);

const App = () => {
  return (
    <Provider store={store}>
      <div className="App">
        <Router>
          <Document>
            <StyledEngineProvider injectFirst>
              <MaterialCssVarsProvider
                theme={{ [MATERIAL_THEME_ID]: materialTheme }}
              >
                <JoyCssVarsProvider>
                  <CssBaseline />
                  <AnalyticsWrapper>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Suspense fallback={<PageLoader />}>
                        <Routes />
                      </Suspense>
                    </LocalizationProvider>
                  </AnalyticsWrapper>
                </JoyCssVarsProvider>
              </MaterialCssVarsProvider>
            </StyledEngineProvider>
          </Document>
        </Router>
      </div>
      <Snackbar />
    </Provider>
  );
};

export default App;
